import React from 'react';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Footer from './components/Footer';
import Services from './components/Services';
import Projects from './components/Projects';
import CallToAction from './components/CallToAction';
import { HashRouter, Routes, Route } from 'react-router-dom';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  return (

    <div>
      <HashRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/call_to_action" element={<CallToAction />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        </Routes>
        <Footer />
      </HashRouter>
    </div>

  );
}

export default App;

import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import Logo from "../assets/logo.png";
import FacebookLogo from "../assets/fblogo.png";
import TikTokLogo from "../assets/tiktok.png";
import XLogo from "../assets/xlogo.png";
import SocialMediaLink from "./SocialMediaLink";

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page smoothly
  };

  return (
    <footer className="bg-gray-900 ">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <Link
            to="/TermsAndConditions"
            className="text-white hover:underline"
            onClick={scrollToTop} // Add onClick to scroll to top
          >
            Terms &amp; Conditions
          </Link>

          <div className="flex flex-col items-start">
            <ul className="text-white font-medium ">
              <li className="mb-4">
                <Link
                  to="/privacy_policy"
                  className="hover:underline"
                  onClick={scrollToTop} // Add onClick to scroll to top
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>

          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-white sm:mb-0 dark:text-white ml-5">
            <p className="pr-8 text-xl">Follow Us</p>
            <SocialMediaLink
              href="https://www.facebook.com/profile.php?id=61550738293751"
              logoSrc={FacebookLogo}
              altText="Facebook Logo"
              bgColorClass="hover:bg-amber"
            />
            <SocialMediaLink
              href="https://www.tiktok.com/@jusamusha"
              logoSrc={TikTokLogo}
              altText="TikTok Logo"
              bgColorClass="hover:bg-amber"
            />
            <SocialMediaLink
              href="https://twitter.com/JusaMusha"
              logoSrc={XLogo}
              altText="X Logo"
              bgColorClass="hover:bg-amber"
            />
          </ul>
        </div>
        <hr className="my-6 border-white sm:mx-auto dark:border-white lg:my-8" />
        <span className="block text-sm text-white sm:text-center dark:text-white">
          © {new Date().getFullYear()} <a href="https://bitwisesoftware.co.za/" className="hover:underline">Bitwise™</a>. All Rights Reserved.
        </span>
      </div>
    </footer>
  );
}

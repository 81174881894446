import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import HeroImage from '../images/hero.png';
import { useState, useEffect } from 'react';
import Projects from './Projects';
import Services from './Services';
import About from './About';
import CallToAction from './CallToAction';

function Home() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <div>
      <Carousel>
        <div>
          <div className="relative isolate overflow-hidden h-[100vh]">
            <img
              src={HeroImage}
              alt=""
              className="absolute inset-0 -z-10 h-full w-full object-cover"
            />
            <div
              className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
              aria-hidden="true"
            >
              <div
                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
              />
            </div>
            <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
              <div className="">
                <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                  We are Bitwise.
                </h1>
                <p className="mt-6 text-xl leading-8 text-gray-300">
                  We deliver software projects in the enterprise on time, on budget and on spec.
                </p>
                <p className="mt-6 text-xl leading-8 text-gray-300">
                  Our active client portfolio spans fintech, health, e-commerce, and more.
                  <br />
                  Our projects are used by tens of thousands daily, locally and beyond.
                </p>
                <p className="mt-6 text-xl leading-8 text-gray-300">
                  We're the technology company building <a href="https://jusamusha.co.za/" className="text-teal-300 underline">Jusa Musha</a>.
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <a
                    href="https://www.linkedin.com/company/bitwisesoftwaresolutions/"
                    className="rounded-md bg-[#008080] px-5 py-3 text-lg font-semibold text-white shadow-sm hover:bg-[#008198f0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                  >
                    Let's Talk About Your Project
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Other slides with the same updates */}
      </Carousel>
      <Projects />
      <Services />
      <About />
      <CallToAction />
    </div>
  );
}

export default Home;
